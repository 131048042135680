<template>
    <div class="container-fluid  p-5">
     
        <div class="row ">
            <div class="col-6 text-end">
                <img class="imghome"
                    src="https://scontent.fbkk13-1.fna.fbcdn.net/v/t39.30808-6/273927124_5066550556740442_1496911650288855787_n.jpg?_nc_cat=108&ccb=1-7&_nc_sid=8bfeb9&_nc_eui2=AeFRosADhM0UpytSMQlT7Y5KaC-1Mh1LrEdoL7UyHUusR6bnc9ko5IVyTvK4wq4X5VnSvAc4zBd3gBHKcZk7_jPY&_nc_ohc=Pfh0mxZm6qgAX_NaPR5&tn=OxaXop81nr1F0eVN&_nc_ht=scontent.fbkk13-1.fna&oh=00_AT_IDsTXxdkw7HBE1Azo21n2vpyc8kIHeMyIUCc55l3HjQ&oe=63011BE3" />
            </div>
            <div class="col-6">
                <div class="row">
                    <div class="col-12">
                        <img class="imghome2"
                            src="https://www.u-sabai.com/wp-content/uploads/2021/02/Harmonize_1778-scaled.jpg" />
                    </div>
                    <div class="col-12 pt-3">
                        <img class="imghome2"
                            src="https://www.u-sabai.com/wp-content/uploads/2021/02/Harmonize_1778-scaled.jpg" />
                    </div>
                </div>
            </div>
        </div>
   
    </div>
</template>
<script>
export default {

}
</script>
<style scoped>
.container-fluid {
    background-color: #A3B8B4;
    height: 100vh;
    width: 100%;
    display: flex;
}

.imghome {
    max-width: 645px;
    min-height: 640px;
}

.imghome2 {
    width: 595px;
    height: 313px;
}
</style>



