<template>
  <link
    rel="stylesheet"
    href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css"
    integrity="sha512-KfkfwYDsLkIlwQp6LFnl8zNdLGxu9YAA1QvwINks4PhcElQSvqcyVLLD9aMhXd13uQjoXtEKNosOWaZqXgel0g=="
    crossorigin="anonymous"
    referrerpolicy="no-referrer"
  />
  <footer class="text-lg-start mt-5">
    <!-- Grid container -->
    <div class="container p-5">
      <!-- Section: Links -->
      <section class="">
        <!--Grid row-->
        <div class="row">
          <!-- Grid column -->
          <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-2 mx-auto mt-3">
            <p id="title" class="text-uppercase mb-3">PROJECTS</p>
            <div class="project-item">
              <div class="row">
                <div
                  class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-12 col-xxl-12"
                >
                  <p>
                    <a class="" style="color: #fffff ; font-weight: 600;">HUA TALAY</a>
                  </p>
                  <p>
                    <router-link  to="/error">the urbano</router-link>
                    <!-- <a
                      class=""
                      href="https://www.u-sabai.com/usabai_project/the-urbano/"
                      >the urbano</a
                    > -->
                    <!-- หัวทะเล -->
                  </p>
                  <p>
                    <router-link  to="/error">u-12</router-link>
                    <!-- <a
                      class=""
                      href="https://www.u-sabai.com/usabai_project/u12/"
                      >u-12</a
                    > -->
                    <!-- หัวทะเล -->
                  </p>

                  <p>
                    <router-link  to="/error">the absolute</router-link>
                    <!-- <a
                      class=""
                      href="https://www.u-sabai.com/usabai_project/the-absolute/"
                      >the absolute</a
                    > -->
                    <!-- หัวทะเล -->
                  </p>

                  <p>
                    <router-link to="/error">the vela</router-link>
                    <!-- หัวทะเล -->
                  </p>

                  <p>
                    <router-link  to="/error">harmonize</router-link>
                    <!-- <a
                      class=""
                      href="https://www.u-sabai.com/usabai_project/harmonize/"
                      >harmonize</a
                    > -->
                    <!-- หัวทะเล -->
                  </p>
                </div>
                <div
                  class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-12 col-xxl-12"
                >
                  <p>
                    <a class="" style="color: #fff ;font-weight: 600;">JOHO</a>
                  </p>

                  <p>
                    <router-link  to="/error">ultima</router-link>
                    <!-- <a
                      class=""
                      href="https://www.u-sabai.com/usabai_project/ultima/"
                      >ultima</a
                    > -->
                  </p>
                  <p>
                    <router-link  to="/error">symbolic</router-link>
                    <!-- <a
                      class=""
                      href="https://www.u-sabai.com/usabai_project/symbolic/"
                      >symbolic</a
                    > -->

                  </p>
                  <p>
                    <router-link to="/error">aurora</router-link>
                     <!-- <a
                      class=""
                      href="https://www.u-sabai.com/usabai_project/aurora/"
                      >aurora</a
                    > -->
                  </p>

                  <p>
                    <router-link to="/error">aspect</router-link>
                    <!-- <a
                      class=""
                      href="https://www.u-sabai.com/usabai_project/aspect/"
                      >aspect</a
                    > -->
                  </p>

                  <p>
                    <router-link to="/error">estella</router-link>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-3 mx-auto mt-3">
            <!-- <p  id="title" class="text-uppercase mb-3 font-weight-bold">ทำเลโครงการ</p>
            <div class="typeproject">
              <p>
                <a class="">หัวทะเล</a>
              </p>
              <p>
                <a class="">จอหอ</a>
              </p>
            </div> -->
            <p id="title" class="mb-1 mt-0">
              <!-- class="text-uppercase mt-5 mb-2 font-weight-bold"
              style="line-height: 30px" -->
              CONTACT US<br />
              OFFICE
            </p>
            <p id="companyname">
              CHOKVIWAT PROPERTY COMPANY LIMITED<br />
              777/708 M8 Nongbuasala. Mueang Nakhon Ratchasima. Distct. Nakhon Ratchasima 30000
            </p>
          </div>

          <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-3 mx-auto mt-3">
            <p id="title" class="mb-1">HUA TALAY ZONE</p>
            <!-- class="text-uppercase mb-1 font-weight-bold" -->
            <p id="numphone"><b>sales office :</b> 099-246-2664</p>

            <p id="title" class="mb-1">
              <!--   class="text-uppercase mb-1 font-weight-bold"
              style="margin-top: 30px" -->
              JOHO ZONE
            </p>
            <p id="numphone"><b>sales office :</b> 099-264-2446</p>

            <p id="title" class="mt-2">
              <!--   class="text-uppercase mb-0 font-weight-bold"
              style="margin-top: 30px" -->
              Follow U-sabai here.
            </p>
            <p class="Social-item">
              <a href="https://www.facebook.com/usabaigroupkorat"
                ><i class="fa-brands fa-facebook fa-xl"></i
              ></a>
              <a href="https://www.instagram.com/u_sabai/"
                ><i class="fa-brands fa-instagram fa-xl"></i
              ></a>
              <a
                href="https://twitter.com/UsabaiGroup?t=GeKUSwpOF-guMw7x6jGBxA&s=09&fbclid=IwAR1A-kfWaurz7z3saKtCGFL2IcwVXdBm2VTRxJh7zV57zNTFkp07td87X6A"
                ><i class="fa-brands fa-twitter fa-xl"></i
              ></a>
              <a href="https://www.youtube.com/channel/UCeMNYzUaw7bpKlUCkJQBqyQ"
                ><i class="fa-brands fa-youtube fa-xl"></i
              ></a>
              <a href="https://www.tiktok.com/@u_sabai"
                ><i class="fa-brands fa-tiktok fa-xl"></i
              ></a>
              <a href="https://page.line.me/698wfedw?openQrModal=true"
                ><i class="fa-brands fa-line fa-xl"></i
              ></a>
            </p>

            <!-- <p class="Social-itemresponsive">
              <i class="fa-brands fa-facebook fa-xl"></i> 
              <i class="fa-brands fa-instagram fa-xl"></i>
              <i class="fa-brands fa-twitter fa-xl"></i>
              <i class="fa-brands fa-youtube fa-xl"></i>
              <i class="fa-brands fa-tiktok fa-xl"></i>
              <i class="fa-brands fa-line fa-xl"></i>
            </p> -->
          </div>

          <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-2 mx-auto mt-3">
            <img
              class="logocompany"
              src="@/assets/logousabai.png"
              style="display: block; margin-left: auto; margin-right: auto"
            />
            <p id="website" class="text-center mt-4 fw-bold">WWW.u-sabai.app</p>
          </div>
          <!-- Grid column        width="100%"-->
        </div>
        <!--Grid row-->
      </section>
      <!-- Section: Links -->
    </div>
    <!-- Grid container -->
  </footer>
</template>

<script>
export default {

}
</script>

<style scoped>
#title {
  font-size: 20px;
  font-weight: 600;
}

a {
   font-weight: 400;
  text-decoration: none;
  color: rgb(255, 255, 255);
  cursor: pointer;
}

footer {
  /* background-color: #e70000; */
background: linear-gradient(269.94deg, #110041 0.06%, #FF1766 99.95%);
  color: rgb(255, 255, 255);
  width: 100%;
}

.project-item p {
  font-weight: bold;
  margin-bottom: 5px;
  text-transform: uppercase;
}

footer p {
  font-size: 15px;
}

.Social-item {
  margin-top: 22px;
}

.Social-item i {
  margin-left: 6px;
}

.typeproject p {
  margin-bottom: 10px;
  font-weight: bold;
}

.Social-itemresponsive {
  display: none;
}

.logocompany {
  /* width="100%" */
  width: 100%;
}

#companyname {
    font-size: 14px;
  }

/*------------------- hover------------------- */

a:hover {
  color: #ff4d6d;
}

.fa-facebook:hover {
  color: #4255ff;
}
.fa-instagram:hover {
  color: #ff4262;
}

.fa-twitter:hover {
  color: #5eaeff;
}

.fa-youtube:hover {
  color: #ff0000;
}

.fa-tiktok:hover {
  color: #898989;
}

.fa-line:hover {
  color: #00b900;
}

@media only screen and (max-width: 1180px) {
  .logocompany {
    width: 45%;
  }
}

/*---------------------------------- media query-------------------------------- */
@media only screen and (max-width: 896px) {
  .project-item p {
    font-weight: bold;
    margin-bottom: 5px;
    font-size: 12px;
  }

  .typeproject p {
    margin-bottom: 8px;
    font-weight: bold;
    font-size: 12px;
  }

  #companyname {
    font-size: 10px;
  }

  #numphone {
    font-size: 10px;
  }
  #title {
    font-size: 16px;
  }
  .logocompany {
    width: 45%;
  }
  #website {
    font-size: 14px;
  }
}

@media only screen and (max-width: 414px) {
  .project-item p {
    font-weight: bold;
    margin-bottom: 5px;
    font-size: 10px;
  }

  .typeproject p {
    margin-bottom: 8px;
    font-weight: bold;
    font-size: 12px;
  }

  #companyname {
    font-size: 10px;
  }

  #numphone {
    font-size: 10px;
  }
  #title {
    font-size: 16px;
  }
  .logocompany {
    width: 80%;
  }
  #website {
    font-size: 10px;
  }
}
</style>
