import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import DetailView from '../views/AboutView.vue'
import errorView from '../views/Errorpage.vue'
import testView from '../views/testrouView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/detail',
    name: 'detail',
    component: DetailView
  },
  {
    path: '/error',
    name: 'error',
    component: errorView
  },{
    path: '/detailproject',
    name: 'test',
    component: testView
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
